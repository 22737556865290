<template>
    <div class="top-footer top-footer-places">
        <v-container>
            <v-row class="places">
                <v-col class="place" v-for="place in footer.places" v-bind:key="place.info">
                    <div class="image">
                        <custom-image
                                v-if="place.image"
                                :image="place"
                                :max-width="`${place.image?.dimensions?.width}px`"
                                height="auto"
                                container-height="auto"
                                :hover="false"
                                :contain="true"
                        />
                    </div>
                    <div class="info" v-html="pp(place.info)"/>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script setup>
import { defineProps } from "vue";
import CustomImage from "@/components/reuseables/customImage.vue";
import { useThemeStore } from "@/store/theme";

const themeStore = useThemeStore();
const pp = themeStore.processPlaceholders;

defineProps({
    footer: {
        type: Object,
        required: true,
    },
});
</script>