<template>
    <router-link :to="'/news/' + data?.node?.slug">
        <custom-image
            class="image"
            :image="getNewsImage(data)"
            container-height="auto"
            cover
        />
        <div class="text-container">
            <div class="meta-information">
                <p class="categories">
                    <span
                        v-for="category in data?.node?.categories"
                        v-bind:key="category.name"
                    >
                        {{ category.name }}
                    </span>
                </p>
                <p class="date">{{ data?.node?.date }}</p>
            </div>
            <h3 v-if="data?.node?.texts?.[0]?.headline">
                {{ data.node.texts[0].headline }}
            </h3>
            <div class="author" v-if="data?.node?.author?.[0]">
                <custom-image
                    :image="data?.node?.author?.[0]?.images?.[0] ?? themeStore.teamMember.defaultImage"
                    container-height="auto"
                    cover
                />
                <span>{{ `${data?.node?.author?.[0]?.firstname} ${data?.node?.author?.[0]?.lastname}` }}</span>
            </div>
        </div>
    </router-link>
</template>

<script setup>
import { defineProps } from 'vue'
import { getNewsImage } from '@/mixins/global/globalMethods'
import CustomImage from '@/components/reuseables/customImage.vue'
import { useThemeStore } from "@/store/theme";

const themeStore = useThemeStore()

defineProps({
    data: Object,
})
</script>
