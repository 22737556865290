<template>
    <v-row :class="[
        'listingColumns-v-row',
        'row-' + data.template
    ]">
        <v-col
            v-for="(item, index) in data.content"
            :key="index"
            :class="[
                'listingColumns-v-col',
                data.widthBase ? 'v-col-' + data.widthBase : '',
                data.widthXS ? 'v-col-xs-' + data.widthXS : '',
                data.widthSM ? 'v-col-sm-' + data.widthSM : '',
                data.widthMD ? 'v-col-md-' + data.widthMD : '',
                data.widthLG ? 'v-col-lg-' + data.widthLG : '',
                data.widthXL ? 'v-col-xl-' + data.widthXL : '',
                'col-' + data.template
            ]"
            :data-index="index + 1"
        >
            <component
                :is="components[data.template]"
                :data="item.element"
                :item="item.element"
                :listingData="data"
                :class="'component-' + data.template"
            />
        </v-col>
    </v-row>
</template>

<script setup>
import teaserBox from "@/components/listings/teaserBox.vue";
import imageCard from "@/components/listings/imageCard.vue";
import imageBox from "@/components/listings/imageBox.vue";
import imageBoxBig from "@/components/listings/imageBoxBig.vue";
import trainerBox from "@/components/listings/trainerBox.vue";
import trainerBoxV2 from "@/components/listings/trainerBoxV2.vue";
import lessonBox from "@/components/listings/lessonBox.vue";
import contactBox from "@/components/listings/contactBox.vue";
import listingCardRow from "@/components/listings/listingCardRow.vue";

import { defineProps } from "vue";

defineProps({
    data: Object,
    items: Object,
});
const components = { teaserBox,imageCard, imageBox, imageBoxBig, trainerBox, trainerBoxV2, lessonBox, contactBox, listingCardRow };
</script>

<style lang="scss" scoped>
.v-col-12 {
    @media (min-width: 0px) {
        :deep(.image) {
            background-position: center;
        }
    }
}
.v-col-sm-12 {
    @media (min-width: 600px) {
        :deep(.image) {
            background-position: center;
        }
    }
}
.v-col-md-12 {
    @media (min-width: 960px) {
        :deep(.image) {
            background-position: center;
        }
    }
}
.v-col-lg-4 {
    @media (min-width: 1280px) {
        &:nth-child(3n - 2) {
            :deep(.image) {
                background-position: left;
            }
        }
        &:nth-child(3n - 1) {
            :deep(.image) {
                background-position: center;
            }
        }
        &:nth-child(3n - 0) {
            :deep(.image) {
                background-position: right;
            }
        }
    }
}
.v-col-xl-4 {
    @media (min-width: 1920px) {
        &:nth-child(3n - 2) {
            :deep(.image) {
                background-position: left;
            }
        }
        &:nth-child(3n - 1) {
            :deep(.image) {
                background-position: center;
            }
        }
        &:nth-child(3n - 0) {
            :deep(.image) {
                background-position: right;
            }
        }
    }
}
</style>
