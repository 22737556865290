<template>
    <div class="upper-footer">
        <v-container>
            <v-row>
                <v-col cols="4" sm="3" md="3" lg="2" xl="1">
                    <custom-image
                        v-if="footer.logo && footer.logo[0]"
                        :image="footer.logo[0]"
                        class="logo"
                        max-width="8rem"
                        height="auto"
                        container-height="auto"
                        :hover="false"
                        :contain="true"
                    />
                </v-col>

                <v-col
                    cols="8"
                    sm="9"
                    offset-lg="0"
                    lg="10"
                    xl="3"
                    class="contact-container"
                >
                    <template v-for="(contact, k) in footer.contact" :key="k">
                        <a
                            v-if="contact.link"
                            class="contact"
                            :key="'footer-contact-' + k"
                            :class="contact.bold ? 'bold' : ''"
                            :href="pp(contact.link)"
                            v-ripple
                            style="text-decoration: underline"
                        >
                            {{ pp(contact.text) }}
                        </a>
                        <p
                            v-else
                            class="contact"
                            :class="contact.bold ? 'bold' : ''"
                        >
                            {{ pp(contact.text) }}
                        </p>
                    </template>
                </v-col>

                <v-col class="d-none social-links">
                    <p>
                        <span class="text">Folgt uns</span>
                        <template v-if="footer.imagesWithLinks">
                            <template
                                    v-for="(image, k) in footer.imagesWithLinks"
                                    :key="'lower-footer-images-with-links' + k"
                            >
                                <router-link
                                        v-if="image?.pageLink?.url"
                                        :to="'/' + image?.pageLink?.url"
                                >
                                    <custom-image
                                            :image="image"
                                            height="2rem"
                                            width="2rem"
                                            container-height="auto"
                                            container-width="auto"
                                            :hover="false"
                                    />
                                </router-link>
                                <a
                                        v-else-if="image?.externalLink"
                                        :href="image?.externalLink"
                                        target="_blank"
                                >
                                    <custom-image
                                            :image="image"
                                            height="2rem"
                                            width="2rem"
                                            container-height="auto"
                                            container-width="auto"
                                            :hover="false"
                                    />
                                </a>
                            </template>
                        </template>
                    </p>
                </v-col>

                <v-col cols="12" xl="8" class="pages">
                    <div
                        v-for="(page, k) in footer.upperPages"
                        :key="'footer-upper-pages-' + k"
                        class="page-row"
                    >
                        <span class="bold mb-2">
                            {{ pp(page.element.urlText) }}
                        </span>
                        <template
                            v-for="(children, j) in page.element.children"
                        >
                            <router-link
                                v-if="children.url"
                                :to="'/' + children.url"
                                :key="'footer-upper-pages-children-' + k + j"
                                v-ripple
                            >
                                {{ pp(children.urlText) }}
                            </router-link>
                        </template>
                    </div>
                </v-col>
            </v-row>

            <v-row class="d-none rss-block">
                <div class="golfy-footer-content-4">
                    <div class="golfy-get-in-touch">
                        <p class="text-white mb-0">{{ pp(footer.rssTitle) }}</p>
                    </div>
                    <div class="golfy-footer-news-text">
                        <p class="text-white">{{ pp(footer.rssDescription) }}</p>
                    </div>
                    <div class="golfy-subscribe-box-button">
                        <a :href="pp(footer.rssButtonLink)" class="rss-btn">{{ pp(footer.rssButtonText) }}</a>
                    </div>
                </div>
            </v-row>
        </v-container>
    </div>
</template>

<script setup>
import { defineProps } from "vue";
import CustomImage from "@/components/reuseables/customImage.vue";
import { useThemeStore } from "@/store/theme";

const themeStore = useThemeStore();
const pp = themeStore.processPlaceholders;

defineProps({
    footer: {
        type: Object,
        required: true,
    },
});
</script>

<style lang="scss" scoped>
.upper-footer {

    .logo {
        max-height: 5rem;
        :deep(.v-img__img) {
            width: 5rem;
        }
    }
    .contact-container {
        display: flex;
        flex-direction: column;
        @media (max-width: 1919px) {
            margin-bottom: 5rem;
        }
        .contact {
            font-size: 1rem;
        }
    }
    .pages {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        @media (max-width: 1280px) {
            margin-top: 2rem;
        }
        a,
        span {
            text-transform: lowercase;
        }
        .page-row {
            flex-grow: 1;
            min-width: 15%;
            max-width: calc((100% / 2) - 1rem);
            display: flex;
            flex-direction: column;
            @media (max-width: 1280px) {
                min-width: calc(100% / 6);
            }
            @media (max-width: 960px) {
                min-width: calc((100% / 3) - 2rem);
                margin: 1rem 0;
            }
            @media (max-width: 600px) {
                min-width: calc((100% / 2) - 1rem);
                margin: 1rem 0;
            }
            a {
                margin-bottom: 0.5rem;
            }
        }
    }
}
</style>
