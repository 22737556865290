<template>
    <template v-if="imageGallery.length > 1">
        <v-carousel v-model="carouselValue">
            <template v-slot:prev="{ props }">
                <custom-button
                    height="100%"
                    @click="props.onClick()"
                    style="background-color: transparent"
                    data-button="prev"
                >
                    <img
                        class="custom-icon"
                        :src="api + '/frontend-icons/caret-left-fill__white.svg'"
                        alt="zurück"
                    />
                </custom-button>
            </template>
            <v-carousel-item
                v-for="(image, i) in gallery"
                :key="i"
            >
                <custom-image
                    :image="image"
                    @click="(visibleRef = !visibleRef), (indexRef = i)"
                    width="100%"
                    cover
                    v-ripple
                />
            </v-carousel-item>

            <template v-slot:next="{ props }">
                <custom-button
                    height="100%"
                    @click="props.onClick()"
                    style="background-color: transparent"
                    data-button="next"
                >
                    <img
                        class="custom-icon"
                        :src="api + '/frontend-icons/caret-right-fill__white.svg'"
                        alt="weiter"
                    />
                </custom-button>
            </template>
        </v-carousel>
        <div v-if="gallery[carouselValue]?.title">
            {{ gallery[carouselValue]?.title }}
        </div>
    </template>

    <div
        class="single-image mb-4"
        v-else-if="gallery.length === 1"
    >
        <custom-image
            :image="gallery[0]"
            width="100%"
            @click="visibleRef = !visibleRef"
            :console-log="true"
            container-height="auto"
            height="auto"
        />
        <div v-if="gallery[0].title">
            {{ gallery[0].title }}
        </div>
    </div>
    <vue-easy-lightbox
        v-if="imageGallery.length > 0"
        :visible="visibleRef"
        :imgs="imageGalleryOriginal"
        :index="indexRef"
        @hide="onHide"
        rotate-disabled
        zoom-disabled
        pinch-disabled
        move-disabled
    />
</template>

<script setup>
    import { defineProps, onMounted, ref } from 'vue'
    import VueEasyLightbox from 'vue-easy-lightbox'
    import CustomImage from '@/components/reuseables/customImage.vue'
    import CustomButton from '@/components/reuseables/customButton.vue'
    import { getImagesForLightBox } from '@/mixins/global/globalMethods'

    const imageGallery = ref({}),
        imageGalleryOriginal = ref({}),
        api = process.env.VUE_APP_API_URL,
        visibleRef = ref(),
        indexRef = ref(),
        onHide = ref(() => (visibleRef.value = false)),
        carouselValue = ref(),
        props = defineProps({
            gallery: {
                type: Array,
                required: true,
            },
        })

    function getImageArray() {
        let array = []
        if (props.gallery) {
            props.gallery.forEach((image) => {
                array.push(image.image)
            })
        }
        imageGallery.value = array
    }

    onMounted(() => {
        imageGalleryOriginal.value = getImagesForLightBox(props?.gallery)
        getImageArray()
    })
</script>

<style lang="scss" scoped>
    :deep(.v-window__controls) {
        padding: 0;
    }

    .single-image {
        position: relative;
    }

    .caption {
        position: absolute;
        bottom: 50px;
        z-index: 999;
        width: 100%;
        background-color: rgba(66, 66, 66, 0.3);
        padding: 1rem 1rem 0 1rem;
        font-weight: bold;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.95);
        text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
    }

    .v-carousel {
        width: 100%;
        height: auto !important;
        aspect-ratio: 1.618/1;

        :deep(.v-window__container) {
            height: 100%;
        }

        :deep(.v-img) {
            height: 100%;
        }


        :deep(.v-window__controls) {
            button {

                &[data-button="prev"] img {
                    -webkit-filter: drop-shadow(-4px 0 0 #222);
                    filter: drop-shadow(-3px 0 0 #222);
                }
                &[data-button="next"] img {
                    -webkit-filter: drop-shadow(-4px 0 0 #222);
                    filter: drop-shadow(3px 0 0 #222);
                }
            }
        }
    }
</style>
