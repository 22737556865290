<template>
    <v-btn
        v-ripple
        class="custom-button"
        @click="$emit('click')"
        flat
        :min-height="minHeight"
        :to="to"
        :href="link.link"
        rounded="0"
        :type="type"
        :style="[{ color: color }, { backgroundColor: bgColor }]"
        :target="target"
    >
        <slot name="before">
            <slot/>
        </slot>
        <template v-if="link.title">
            {{ link.title }}
        </template>
        <slot name="after"/>
    </v-btn>
</template>

<script>
export default {
    name: "CustomButton",
    data() {
        return {
            link: {}
        }
    },
    props: {
        // TYPE
        type: {
            type: String,
            required: false,
            default: "button",
            validator(value) {
                return ["button", "link", "submit"].includes(value);
            },
        },
        to: {
            type: [String, Object],
            required: false,
        },
        generalLink: {
            type: Object,
            required: false,
        },
        target: {
            type: String,
            required: false,
            default: "_self",
        },
        color: {
            type: String,
            required: false,
            default: "black",
        },
        bgColor: {
            type: String,
            required: false,
        },
        minHeight: {
            type: String,
        },
    },

    beforeMount() {
        this.processedGeneralLink();
    },

    methods: {
        processedGeneralLink() {
            if (this.generalLink) {
                const {link, dataObject, title, classes} = this.generalLink;
                let finalLink = '';
                if (link) finalLink = link;
                else if (dataObject && dataObject.url) finalLink = `/${dataObject.url}`;
                const finalTitle = title || dataObject?.urlText;

                return this.link = {link: finalLink, title: finalTitle, classes};
            }

            return this.link = {link: null, title: null, classes: null};
        }
    }
}
</script>

<style lang="scss" scoped>
.custom-button {
    letter-spacing: 0.5px;
    text-transform: unset;
    height: auto !important;

    :deep(i) {
        margin-bottom: 0.125rem;
        margin-left: 0.25rem;
    }
    :deep(.v-btn__content) {
        white-space: normal;
        text-align: center;
    }
}
</style>
